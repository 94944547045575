<template>
  <div class="scrobbler-container">
    <div class="scrobbler-bg">
  </div>
  <v-card class="scrobbler-main time-scrobbler" dark flat style="">
    <div class="selection-frame" style="background: transparent !important;">
      <div 
        class="minute-slider"
        ref="minuteSlider"
        @pointerdown="startMinuteDrag"
        @wheel.prevent="handleMinuteScroll"
      >
        <div class="minute-track">
          <div 
            class="minute-progress"
            :style="{ width: `${(selectedMinute / 59) * 100}%` }"
          ></div>
        </div>
        <div 
          class="minute-handle"
          :style="{ left: `${(selectedMinute / 59) * 100}%` }"
        ></div>
      </div>
    </div>
    
    <div class="scroll-container">
      <div 
        class="track"
        ref="dateTrack"
        @wheel.prevent="handleScroll($event, 'date')"
        @pointerdown="startDrag($event, 'date')"
      >
        <div 
          class="track-content"
          :style="getTrackStyle('date')"
        >
          <div class="spacer" :style="edgeSpacerStyle"></div>
          <div 
            v-for="(date, index) in visibleDates" 
            :key="date.getTime()"
            class="item"
            :class="{ 'selected': isSelectedDate(date) }"
            @click="handleItemClick('date', index)"
          >
            {{ formatDate(date) }}
          </div>
          <div class="spacer" :style="edgeSpacerStyle"></div>
        </div>
      </div>
  
      <div 
        class="track"
        ref="timeTrack"
        @wheel.prevent="handleScroll($event, 'time')"
        @pointerdown="startDrag($event, 'time')"
      >
        <div 
          class="track-content"
          :style="getTrackStyle('time')"
        >
          <div class="spacer" :style="edgeSpacerStyle"></div>
          <div 
            v-for="time in times" 
            :key="time.value"
            class="item"
            :class="{ 'selected': time.value === selectedTime }"
            @click="handleItemClick('time', time.value)"
          >
            {{ formatTime(time.value, selectedMinute) }}
          </div>
          <div class="spacer" :style="edgeSpacerStyle"></div>
        </div>
      </div>
  
      <div class="button-track">
        <div class="d-flex align-center">
          <v-btn 
            small 
            icon 
            class="mr-2"
            @click="toggleDirection"
          >
            <v-icon>{{ isForward ? 'mdi-fast-forward' : 'mdi-rewind' }}</v-icon>
          </v-btn>
          
          <v-btn 
            small 
            icon 
            class="mr-2"
            @click="togglePlayback"
          >
            <v-icon>{{ isPlaying ? 'mdi-pause' : 'mdi-play' }}</v-icon>
          </v-btn>
  
          <v-select
v-model="speedMultiplier"
:items="speedOptions"
dense
outlined
hide-details
class="speed-select mr-2"
style="max-width: 100px;"
></v-select>

<v-select
v-model="cycleDelay"
:items="cycleDelayOptions"
dense
outlined
hide-details
class="speed-select mr-4"
style="max-width: 100px;"
></v-select>

  
          <v-btn
            small
            :color="isRealtime ? 'primary' : ''"
            :outlined="isRealtime"
            class="mr-4"
            @click="toggleRealtime"
          >
            <v-icon left small>mdi-clock-outline</v-icon>
            Live
          </v-btn>
        </div>
      </div>
    </div>
    

  </v-card>  
</div>
  </template>

<script>
const ITEM_WIDTH = 100
const SCROLL_SENSITIVITY = 1

export default {
name: 'TimeScrobbler',

props: {
  value: {
    type: Date,
    default: () => {
      const date = new Date();
      date.setSeconds(0);
      date.setMilliseconds(0);
      return date;
    }
  }
},

data: () => ({
  selectedDate: null, // Will be set in created hook
  positions: {
    date: 0,
    time: 0
  },
  drag: {
    active: false,
    type: null,
    startX: 0,
    lastX: 0,
    lastTime: 0,
    initialPosition: 0,
    velocity: 0,
    frame: null,
    moved: false
  },
  minuteDrag: {
    active: false,
    startX: 0
  },
  containerWidth: 0,
  visibleDates: [],
  times: [],
  isPlaying: false,
  isForward: true,
  isRealtime: false,
  speedMultiplier: 1,
  speedOptions: [
    { text: '1x', value: 1 },
    { text: '2x', value: 2 },
    { text: '5x', value: 5 },
    { text: '30x', value: 30 },
    { text: '60x', value: 60 },
    { text: '1440x', value: 1440 }
  ],
  cycleDelay: 1000,
  cycleDelayOptions: [
    { text: '1s', value: 1000 },
    { text: '500ms', value: 500 },
    { text: '250ms', value: 250 },
    { text: '100ms', value: 100 },
    { text: '50ms', value: 50 }
  ],
  playbackInterval: null,
  realtimeInterval: null,
  resizeObserver: null
}),

computed: {
  selectedTime() {
    return this.selectedDate.getHours()
  },
  selectedMinute() {
    return this.selectedDate.getMinutes()
  },
  edgeSpacerStyle() {
    return { width: `${(this.containerWidth - ITEM_WIDTH) / 2}px` }
  }
},

watch: {
  value: {
    handler(newValue) {
      if (newValue.getTime() !== this.selectedDate.getTime()) {
        this.selectedDate = new Date(newValue);
        this.initializePositions();
      }
    },
    deep: true
  },
  speedMultiplier() {
    this.restartPlayback()
  },
  cycleDelay() {
    this.restartPlayback()
  }
},

methods: {
  recenterSelections() {
    const dateIndex = Math.round(this.positions.date / ITEM_WIDTH)
    const timeIndex = Math.round(this.positions.time / ITEM_WIDTH)
    
    this.snapToIndex('date', dateIndex)
    this.snapToIndex('time', timeIndex)
  },

  stopAllPlayback() {
    this.isPlaying = false
    this.isRealtime = false
    this.stopPlayback()
    this.stopRealtimeUpdates()
  },

  toggleRealtime() {
    this.isRealtime = !this.isRealtime
    if (this.isRealtime) {
      this.stopPlayback()
      this.isPlaying = false
      this.syncToRealtime()
      this.startRealtimeUpdates()
    } else {
      this.stopRealtimeUpdates()
    }
  },

  syncToRealtime() {
    this.selectedDate = new Date()
    const dateIndex = this.visibleDates.findIndex(date => this.isSelectedDate(date))
    this.positions.date = dateIndex * ITEM_WIDTH
    this.positions.time = this.selectedTime * ITEM_WIDTH
    this.emitUpdate()
  },

  startRealtimeUpdates() {
    this.stopRealtimeUpdates()
    this.realtimeInterval = setInterval(() => {
      this.syncToRealtime()
    }, 1000)
  },

  stopRealtimeUpdates() {
    if (this.realtimeInterval) {
      clearInterval(this.realtimeInterval)
      this.realtimeInterval = null
    }
  },

  togglePlayback() {
    this.isRealtime = false
    this.stopRealtimeUpdates()
    this.isPlaying = !this.isPlaying
    
    if (this.isPlaying) {
      this.startPlayback()
    } else {
      this.stopPlayback()
    }
  },

  startPlayback() {
    this.stopPlayback()
    
    this.playbackInterval = setInterval(() => {
      const minutesToIncrement = this.speedMultiplier
      
      for(let i = 0; i < minutesToIncrement; i++) {
        if (this.isForward) {
          this.incrementMinute()
        } else {
          this.decrementMinute()
        }
      }
    }, this.cycleDelay)
  },

  stopPlayback() {
    if (this.playbackInterval) {
      clearInterval(this.playbackInterval)
      this.playbackInterval = null
    }
  },

  restartPlayback() {
    if (this.isPlaying) {
      this.stopPlayback()
      this.startPlayback()
    }
  },

  toggleDirection() {
    this.stopAllPlayback()
    this.isForward = !this.isForward
    if (this.isPlaying) {
      this.restartPlayback()
    }
  },

  incrementMinute() {
    const newDate = new Date(this.selectedDate)
    newDate.setMinutes(this.selectedMinute + 1)

    if (this.selectedMinute === 59) {
      this.selectedDate = newDate
      this.snapToIndex('time', this.selectedTime)
      
      if (this.selectedTime === 0) {
        const dateIndex = this.visibleDates.findIndex(date => this.isSelectedDate(date))
        this.snapToIndex('date', dateIndex)
      }
    } else {
      this.selectedDate = newDate
    }

    this.emitUpdate()
  },

  decrementMinute() {
    const newDate = new Date(this.selectedDate)
    newDate.setMinutes(this.selectedMinute - 1)

    if (this.selectedMinute === 0) {
      this.selectedDate = newDate
      this.snapToIndex('time', this.selectedTime)
      
      if (this.selectedTime === 23) {
        const dateIndex = this.visibleDates.findIndex(date => this.isSelectedDate(date))
        this.snapToIndex('date', dateIndex)
      }
    } else {
      this.selectedDate = newDate
    }

    this.emitUpdate()
  },

  incrementHour() {
    const newDate = new Date(this.selectedDate)
    newDate.setHours(this.selectedTime + 1)
    this.selectedDate = newDate
    this.snapToIndex('time', this.selectedTime)
  },

  decrementHour() {
    const newDate = new Date(this.selectedDate)
    newDate.setHours(this.selectedTime - 1)
    this.selectedDate = newDate
    this.snapToIndex('time', this.selectedTime)
  },

  incrementDay() {
    const newDate = new Date(this.selectedDate)
    newDate.setDate(newDate.getDate() + 1)
    this.selectedDate = newDate
    const currentIndex = Math.round(this.positions.date / ITEM_WIDTH)
    this.snapToIndex('date', currentIndex + 1)
  },

  decrementDay() {
    const newDate = new Date(this.selectedDate)
    newDate.setDate(newDate.getDate() - 1)
    this.selectedDate = newDate
    const currentIndex = Math.round(this.positions.date / ITEM_WIDTH)
    this.snapToIndex('date', currentIndex - 1)
  },

  getTrackStyle(type) {
    return {
      transform: `translateX(${-this.positions[type]}px)`,
      transition: this.drag.active ? 'none' : 'transform 0.2s cubic-bezier(0.4, 0.0, 0.2, 1)'
    }
  },

  handleItemClick(type, index) {
    if (!this.drag.moved) {
      this.stopAllPlayback()
      this.snapToIndex(type, index)
    }
  },

  handleScroll(event, type) {
    this.stopAllPlayback()
    const delta = Math.sign(event.deltaX || event.deltaY) * SCROLL_SENSITIVITY
    const currentIndex = Math.round(this.positions[type] / ITEM_WIDTH)
    let newIndex = currentIndex + delta
    
    if (type === 'time') {
      if (newIndex > 23) {
        newIndex = 0
        this.incrementDay()
      } else if (newIndex < 0) {
        newIndex = 23
        this.decrementDay()
      }
    }

    this.snapToIndex(type, newIndex)
  },

  handleMinuteScroll(event) {
    this.stopAllPlayback()
    const delta = Math.sign(event.deltaY)
    let newMinute = this.selectedMinute + delta

    if (newMinute > 59) {
      newMinute = 0
      this.incrementHour()
    } else if (newMinute < 0) {
      newMinute = 59
      this.decrementHour()
    } else {
      const newDate = new Date(this.selectedDate)
      newDate.setMinutes(newMinute)
      this.selectedDate = newDate
      this.emitUpdate()
    }
  },

  startDrag(event, type) {
    this.stopAllPlayback()
    if (this.drag.frame) {
      cancelAnimationFrame(this.drag.frame)
    }

    this.drag = {
      active: true,
      type,
      startX: event.clientX,
      lastX: event.clientX,
      lastTime: performance.now(),
      initialPosition: this.positions[type],
      velocity: 0,
      frame: null,
      moved: false
    }

    window.addEventListener('pointermove', this.handleDrag, { passive: true })
    window.addEventListener('pointerup', this.endDrag)
  },

  handleDrag(event) {
    if (!this.drag.active) return

    const currentTime = performance.now()
    const deltaTime = currentTime - this.drag.lastTime
    const deltaX = event.clientX - this.drag.lastX
    
    if (Math.abs(event.clientX - this.drag.startX) > 5) {
      this.drag.moved = true
    }
    
    if (deltaTime > 0) {
      this.drag.velocity = deltaX / deltaTime
    }

    if (this.drag.frame) {
      cancelAnimationFrame(this.drag.frame)
    }

    this.drag.frame = requestAnimationFrame(() => {
      const totalDelta = this.drag.startX - event.clientX
      const newPosition = this.drag.initialPosition + totalDelta
      const maxPosition = (this.drag.type === 'date' ? this.visibleDates.length - 1 : 23) * ITEM_WIDTH
      
      this.positions[this.drag.type] = Math.max(0, Math.min(newPosition, maxPosition))
      this.updateSelection(this.drag.type)
    })

    this.drag.lastX = event.clientX
    this.drag.lastTime = currentTime
  },

  endDrag() {
    if (!this.drag.active) return

    if (this.drag.frame) {
      cancelAnimationFrame(this.drag.frame)
    }

    const currentIndex = Math.round(this.positions[this.drag.type] / ITEM_WIDTH)
    
    const momentumIndex = currentIndex - Math.sign(this.drag.velocity) * 
      (Math.min(Math.abs(this.drag.velocity * 0.5), 1))
    
    this.snapToIndex(this.drag.type, Math.round(momentumIndex))

    this.drag.active = false
    window.removeEventListener('pointermove', this.handleDrag)
    window.removeEventListener('pointerup', this.endDrag)
  },

  startMinuteDrag(event) {
    this.stopAllPlayback()
    event.preventDefault()
    
    this.minuteDrag = {
      active: true,
      startX: event.clientX
    }

    this.updateMinuteFromEvent(event)
    
    window.addEventListener('pointermove', this.handleMinuteDrag)
    window.addEventListener('pointerup', this.endMinuteDrag)
  },

  handleMinuteDrag(event) {
    if (!this.minuteDrag.active) return
    this.updateMinuteFromEvent(event)
  },

  updateMinuteFromEvent(event) {
    const sliderRect = this.$refs.minuteSlider.getBoundingClientRect()
    const percentage = (event.clientX - sliderRect.left) / sliderRect.width
    const minute = Math.round(Math.max(0, Math.min(percentage, 1)) * 59)
    
    if (minute !== this.selectedMinute) {
      const newDate = new Date(this.selectedDate)
      newDate.setMinutes(minute)
      this.selectedDate = newDate
      this.emitUpdate()
    }
  },

  endMinuteDrag() {
    this.minuteDrag.active = false
    window.removeEventListener('pointermove', this.handleMinuteDrag)
    window.removeEventListener('pointerup', this.endMinuteDrag)
  },

  snapToIndex(type, index) {
    const maxIndex = type === 'date' ? this.visibleDates.length - 1 : 23
    const safeIndex = Math.max(0, Math.min(index, maxIndex))
    
    this.positions[type] = safeIndex * ITEM_WIDTH
    this.updateSelection(type)
  },

  updateSelection(type) {
    const index = Math.round(this.positions[type] / ITEM_WIDTH)

    if (type === 'date') {
      const newDate = this.visibleDates[index]
      if (newDate && !this.isSelectedDate(newDate)) {
        const updatedDate = new Date(newDate)
        updatedDate.setHours(this.selectedTime, this.selectedMinute)
        this.selectedDate = updatedDate
        this.emitUpdate()
      }
    } else if (type === 'time') {
      if (index !== this.selectedTime) {
        const newDate = new Date(this.selectedDate)
        newDate.setHours(index)
        this.selectedDate = newDate
        this.emitUpdate()
      }
    }
  },

  emitUpdate() {
    const date = new Date(this.selectedDate);
    date.setSeconds(0);
    date.setMilliseconds(0);
    this.$emit('input', date);
    this.$emit('update', {
      date: date
    });
  },

  formatDate(date) {
    return new Intl.DateTimeFormat('en-US', {
      weekday: 'short',
      month: 'short',
      day: 'numeric'
    }).format(date)
  },

  formatTime(hour, minute = 0) {
    return `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`
  },

  isSelectedDate(date) {
    return date.toDateString() === this.selectedDate.toDateString()
  },

  initializeData() {
    this.times = Array.from({ length: 24 }, (_, i) => ({
      value: i,
      label: i.toString().padStart(2, '0') + ':00'
    }))

    const dates = []
    const baseDate = new Date()
    for (let i = -180; i <= 180; i++) {
      const date = new Date(baseDate)
      date.setDate(baseDate.getDate() + i)
      dates.push(date)
    }
    this.visibleDates = dates
  },

  initializePositions() {
    const dateIndex = this.visibleDates.findIndex(date => this.isSelectedDate(date))
    this.positions.date = dateIndex * ITEM_WIDTH
    this.positions.time = this.selectedTime * ITEM_WIDTH
  }
},

created() {
  this.selectedDate = new Date(this.value);
  this.initializeData();
},

mounted() {
  this.containerWidth = this.$el.offsetWidth
  this.initializePositions()

  this.resizeObserver = new ResizeObserver(entries => {
    for (const entry of entries) {
      const newWidth = entry.contentRect.width
      if (this.containerWidth !== newWidth) {
        this.containerWidth = newWidth
        this.recenterSelections()
      }
    }
  })

  this.resizeObserver.observe(this.$el)
},

beforeDestroy() {
  if (this.resizeObserver) {
    this.resizeObserver.disconnect()
  }
  if (this.drag.frame) {
    cancelAnimationFrame(this.drag.frame)
  }
  this.stopPlayback()
  this.stopRealtimeUpdates()
}
}
</script>

  
  
  <style scoped>

.scrobbler-container {
display: grid;
grid-template-columns: 1fr;
grid-template-rows: 1fr;
grid-auto-columns: 1fr;
gap: 0px 0px;
grid-auto-flow: row;
position: relative; /* Add this */
}

.scrobbler-bg {
grid-area: 1 / 1 / 2 / 2;
z-index: 1; /* Lower z-index */
}

.scrobbler-main {
grid-area: 1 / 1 / 2 / 2;
z-index: 2; /* Higher z-index */
}



  .time-scrobbler {
  width: 100%;
  height: 150px;
  margin-top: 0px !important;
  background: #1a1a1a !important;
  position: relative;
  overflow: hidden;
  touch-action: none;
  user-select: none;
  }
  
  .scroll-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  }
  
  .selection-frame {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 120px;
  border: 2px solid #3478F6;
  border-radius: 8px;
  pointer-events: none;
  z-index: 2;
  }
  
  .track {
  flex: 1;
  position: relative;
  overflow: hidden;
  }
  
  .track-content {
  position: absolute;
  display: flex;
  height: 100%;
  will-change: transform;
  }
  
  .item {
  flex: 0 0 100px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  color: #666;
  font-weight: 500;
  cursor: pointer;
  }
  
  .item.selected {
  color: #fff;
  font-weight: 600;
  }
  
  .minute-slider {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 24px;
  cursor: pointer;
  pointer-events: auto;
  }
  
  .minute-track {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 8px;
  background: rgba(255, 255, 255, 0.1);
  overflow: hidden;
  }
  
  .minute-progress {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: #3478F6;
  transition: width 0.1s ease;
  }
  
  .minute-handle {
  position: absolute;
  top: 50%;
  width: 12px;
  height: 12px;
  background: #3478F6;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 0 2px rgba(52, 120, 246, 0.2);
  }
  
  .minute-handle:hover {
  background: #4488FF;
  }
  
  .button-track {
  height: 40px;
  padding: 0 20px;
  background: rgba(255, 255, 255, 0.05);
  }
  
  .speed-select ::v-deep .v-input__control {
  min-height: 28px;
  }
  
  .speed-select ::v-deep .v-input__slot {
  min-height: 28px;
  }
  
  .spacer {
  flex: 0 0 auto;
  height: 100%;
  }
  
  .track::before,
  .track::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100px;
  z-index: 1;
  pointer-events: none;
  }
  
  .track::before {
  left: 0;
  background: linear-gradient(to right, 
    rgba(26,26,26,1) 0%,
    rgba(26,26,26,0) 100%);
  }
  
  .track::after {
  right: 0;
  background: linear-gradient(to left, 
    rgba(26,26,26,1) 0%,
    rgba(26,26,26,0) 100%);
  }
  </style>
  