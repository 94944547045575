<template>
    <div style="height: calc(100vh - 214px);">
        <div v-if="!fetchProgress.isLoading" class="main-container">
            <!-- Top Controls Row -->
        <div class="top-controls">
            <v-row>
                <v-col cols="3" v-for="(control, index) in controls" :key="index">
                    <v-text-field v-if="control.type === 'input'" :value="control.value" v-bind="control.props"
                        hide-details @input="control.onChange" disabled></v-text-field>
                    <v-switch v-else-if="control.type === 'switch'" :input-value="control.value" v-bind="control.props"
                        @change="control.onChange"></v-switch>
                </v-col>
            </v-row>
        </div>

        <!-- Middle Content Row -->
        <div class="content-row">
            <!-- Left side - Grid View -->
            <div class="grid-selector">
                <!-- Grid Container -->
                <div ref="gridContainer" class="grid-container" tabindex="0" @keydown.stop="handleKeyDown">
                    <div class="fixed-corner"></div>
                    <div class="fixed-column-headers" ref="columnHeaders"></div>
                    <div class="fixed-row-headers" ref="rowHeaders"></div>
                    <div class="main-grid" ref="mainGrid"></div>
                    <!-- Grid Container Controls -->
                    <div
                        style="position: absolute; bottom: 16px; right: 16px; z-index: 5; width: 50px; height: 50px;background-color: transparent;">
                        <v-btn fab small color="primary" @click="resetView">
                            <v-icon>mdi-magnify-expand</v-icon>
                        </v-btn>
                    </div>
                </div>
            </div>

            <!-- Right side - Panel -->
            <div class="right-panel">
                <v-card dark class="pa-4 right-panel-content" style="margin-top:0px;">
                    <v-card-title class="text-h6">
                        Selected Cells ({{ selectedCells.size }})
                    </v-card-title>
                    <v-card-text>
                        <v-list dark dense v-if="selectedCells.size > 0">
                            <v-list-item v-for="cell in selectedCellDetails" :key="cell.key">
                                <v-list-item-content>
                                    <v-list-item-title class="mb-1">
                                        {{ cell.mediaName }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle class="text-caption">
                                        ID: {{ cell.mediaId }} | Location: {{ cell.location }}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-btn icon small @click="handleCellSelection(cell.coords, {})">
                                        <v-icon>mdi-close</v-icon>
                                    </v-btn>
                                </v-list-item-action>
                            </v-list-item>
                        </v-list>
                        <div v-else class="text-center pa-4">
                            No cells selected
                        </div>
                    </v-card-text>
                    <div v-if="hoverData.hasContent">
                        <v-img :src="hoverData.contentUrl" class="mb-2"></v-img>
                        <div class="subtitle-2">Media: {{ currentHoverInfo.mediaName }}</div>
                        <div class="subtitle-2">Location: {{ currentHoverInfo.location }}</div>
                    </div>
                    <!-- {{ currentHoverInfo }} -->
                </v-card>
            </div>
        </div>

        <!-- Bottom Row -->
        <div class="bottom-row">
            <TimeScrobbler v-model="selectedDateTime" :min="minDateTime" :max="maxDateTime" />
        </div>

        </div>
        <div v-else class="d-flex justify-center align-center" style="height: 100%">
            <div class="text-center">

                <v-progress-circular
                :rotate="-90"
      :size="100"
      :value="fetchProgress.percentComplete"
      color="primary"
    >
      {{ fetchProgress.percentComplete }}%
    </v-progress-circular>
                <div class="mt-4">
                    <span class="font-weight-bold">Loading Creating Flighting Summary</span>
                    
                    <br />
                    <span class="text-caption">
                        {{ `Screens: ${fetchProgress.processedScreens} / ${fetchProgress.totalScreens}` }}
                        <br />
                        {{ `Current Screen: ${fetchProgress.currentScreen}` }}
                        <br />
                        {{ `Errors: ${fetchProgress.errors.join(', ')}` }}
                    </span>
                </div>
            </div>
        </div>

    </div>

</template>

<script>
import * as d3 from 'd3';
import { debounce } from 'lodash';
import TimeScrobbler from './TimeScrobbler.vue';
import CampaignController from '@/services/controllers/Campaign'
import moment from 'moment'

export default {
    name: 'GridSelector',
    components: {
        TimeScrobbler
    },
    data() {
        return {
            // Store the selected date/time as a Date (fixes the prop type warning)
            selectedDateTime: null,
            gridWidth: 0,
            gridHeight: 0,
            cellSize: 100,
            margin: { top: 100, right: 100, bottom: 100, left: 100 },
            headerSize: 100,
            svg: null,
            mainGroup: null,
            zoom: null,
            currentTransform: null,
            columnHeadersGroup: null,
            rowHeadersGroup: null,
            selectedCells: new Set(),
            dragThreshold: 5,
            isCtrlPressed: false,
            autoZoomEnabled: true,
            // Caches and maps for cell content and date formatting
            cellContentCache: new Map(),
            summariesByDateTime: new Map(),
            formattedDateTimes: {},

            hoveredCell: null,
            hoverData: {
                mediaId: null,
                location: null,
                position: null
            },

            flightSummaries: [],
            screenContents: [],
            campaign: null,
            metadata: null,

            fetchProgress: {
                isLoading: false,
                totalScreens: 0,
                processedScreens: 0,
                percentComplete: 0,
                currentScreen: null,
                errors: []
            },

            useDevData: true,
        };
    },
    computed: {
        currentHoverInfo() {
            if (!this.hoveredCell) return null;

            const { mediaId, location, position, hasContent, contentUrl } = this.hoverData;
            const media = this.mediaRows[position.col]; // Changed from position.row to position.col

            return {
                mediaName: mediaId === 0 ? 'Default' : media.media.originalFileName,
                mediaId,
                location,
                position: `(${position.row + 1}, ${position.col + 1})`,
                hasContent,
                contentUrl
            };
        },
        zoomPercentage() {
            if (!this.currentTransform || !this.$refs.mainGrid) return 0;

            // Get the current scale from transform
            const currentScale = this.currentTransform.k;

            // Calculate the minimum scale that fits the grid (same as in setupZoom)
            const container = this.$refs.mainGrid;
            const containerWidth = container.clientWidth;
            const containerHeight = container.clientHeight;
            const gridPixelWidth = this.gridWidth * this.cellSize;
            const gridPixelHeight = this.gridHeight * this.cellSize;
            const scaleX = containerWidth / gridPixelWidth;
            const scaleY = containerHeight / gridPixelHeight;
            const minScale = Math.min(scaleX, scaleY);

            // Get the maximum scale from zoom behavior
            const maxScale = this.zoom ? this.zoom.scaleExtent()[1] : 30;

            // Add some padding to the minimum scale (e.g., allow zooming out 25% more than fit-to-view)
            const extendedMinScale = minScale * 0.75;

            // Convert to percentage (0-100)
            const percentage = ((currentScale - extendedMinScale) / (maxScale - extendedMinScale)) * 100;

            // Round to nearest integer and clamp between 0 and 100
            return Math.max(0, Math.min(100, percentage));
        },
        // Compute unique locations from ALL summaries (columns)
        uniqueLocations() {
            const locations = new Set();
            this.flightSummaries.forEach(summary => {
                summary.groups.forEach(group => locations.add(group));
            });
            return Array.from(locations).sort();
        },

        mediaRows() {
            const columns = [{ id: 0, media: { originalFileName: 'Default' } }];
            this.screenContents.forEach(content => columns.push(content));
            return columns;
        },

        // Minimum date/time for TimeScrobbler (converted to a Date)
        minDateTime() {
            const dates = this.flightSummaries.map(item => new Date(item.dateTime).getTime());
            return new Date(Math.min(...dates));
        },
        // Maximum date/time for TimeScrobbler (converted to a Date)
        maxDateTime() {
            const dates = this.flightSummaries.map(item => new Date(item.dateTime).getTime());
            return new Date(Math.max(...dates));
        },
        // Update the details for the selected cells – using mediaRows instead of screen IDs
        selectedCellDetails() {
            return Array.from(this.selectedCells).map(cellKey => {
                const [row, col] = cellKey.split(',').map(Number);
                const media = this.mediaRows[col]; // Make sure we're using the correct index

                // Check if media exists before accessing its properties
                if (!media) return null;

                return {
                    key: cellKey,
                    coords: [row, col],
                    mediaId: media.id || 0,
                    mediaName: media.id === 0 ? 'Default' : (media.media?.originalFileName || 'Unknown'),
                    location: this.uniqueLocations[row] || 'Unknown'
                };
            }).filter(cell => cell !== null); // Filter out any null entries
        },
        // Controls remain the same; grid dimensions will be updated on grid re-initialisation
        controls() {
            return [
                {
                    type: 'input',
                    value: this.gridWidth,
                    props: {
                        label: 'Columns',
                        type: 'number',
                        dark: true,
                        outlined: true,
                        dense: true,
                        min: '1',
                        rules: [v => v > 0 || 'Must be greater than 0']
                    },
                    onChange: () => this.debounceUpdate()
                },
                {
                    type: 'input',
                    value: this.gridHeight,
                    props: {
                        label: 'Rows',
                        type: 'number',
                        dark: true,
                        outlined: true,
                        dense: true,
                        min: '1',
                        rules: [v => v > 0 || 'Must be greater than 0']
                    },
                    onChange: () => this.debounceUpdate()
                },
                {
                    type: 'input',
                    value: this.cellSize,
                    props: {
                        label: 'Cell Size',
                        type: 'number',
                        dark: true,
                        outlined: true,
                        dense: true,
                        min: '50',
                        max: '200',
                        rules: [v => v >= 50 && v <= 200 || 'Size must be between 50 and 200']
                    },
                    onChange: (val) => this.updateCellSize(val)
                },
                {
                    type: 'switch',
                    value: this.autoZoomEnabled,
                    props: {
                        label: 'Auto Zoom',
                        dark: true,
                        dense: true,
                        hideDetails: true,
                        color: 'primary'
                    },
                    onChange: (val) => this.autoZoomEnabled = val
                }
            ];
        }
    },
    watch: {
        // When the selected date/time changes, clear the cell cache and update the images.
        selectedDateTime: {
            handler() {
                this.clearCaches();
                this.updateImageUrls();
            }
        },
        selectedCells: {
            handler() {
                this.updateCellStyles();
            },
            deep: true
        }
    },
    async created() {
        this.debounceUpdate = debounce(this.initializeGrid, 300);
        try {
            const data = await this.fetchCampaignData();
            this.flightSummaries = data.flightSummaries;
            this.screenContents = data.screenContents;
            this.metadata = data.metadata;
            this.campaign = data.campaign;
            this.initializeCaches();

            if (this.flightSummaries.length > 0) {
                this.selectedDateTime = new Date(this.flightSummaries[0].dateTime);
            }

            this.$nextTick(() => {
                if (this.$refs.gridContainer) {
                    this.initializeGrid();
                    this.setupResizeObserver();
                    this.setupKeyboardListeners();
                }
            });
        } catch (error) {
            console.error('Campaign data fetch failed:', error);
        }
    },
    mounted() {
        this.$nextTick(() => {
            if (this.$refs.gridContainer) {
                this.initializeGrid();
                this.setupResizeObserver();
                this.setupKeyboardListeners();
            }
        });
    },
    beforeDestroy() {
        if (this.resizeObserver) {
            this.resizeObserver.disconnect();
        }
        if (this.cleanup) {
            this.cleanup();
        }
        window.removeEventListener('keydown', this.handleKeyDown);
        window.removeEventListener('keyup', this.handleKeyUp);
        this.clearCaches();
        this.summariesByDateTime.clear();
        this.formattedDateTimes = {};
    },
    methods: {

async fetchCampaignData() {
  this.fetchProgress.isLoading = true;
  this.fetchProgress.errors = [];
  this.fetchProgress.processedScreens = 0;
  this.fetchProgress.percentComplete = 0;

  try {
      // Development mode - return mock data with simulated delay
    //   if (this.useDevData) {
    //       console.log('Using development mock data');
          
    //       // Simulate loading delay and progress updates
    //       const mockScreens = 5; // Simulate 5 screens for dev
    //       this.fetchProgress.totalScreens = mockScreens;
          
    //       for (let i = 0; i < mockScreens; i++) {
    //           this.fetchProgress.currentScreen = `dev-screen-${i + 1}`;
              
    //           // Simulate random processing time between 0.5 and 2 seconds per screen
    //           await new Promise(resolve => 
    //               setTimeout(resolve, Math.random() * 1500 + 500)
    //           );
              
    //           this.fetchProgress.processedScreens++;
    //           this.fetchProgress.percentComplete = 
    //               Math.round((this.fetchProgress.processedScreens / this.fetchProgress.totalScreens) * 100);
              
    //           // Simulate random error for demonstration (20% chance)
    //           if (Math.random() < 0.2) {
    //               this.fetchProgress.errors.push(`Simulated error for screen dev-screen-${i + 1}`);
    //           }
    //       }

    //       return {
    //           campaign: {
    //               id: 'dev-campaign',
    //               name: 'Development Campaign'
    //           },
    //           screenContents: require('@/assets/dev/cfs/GetScreenContentExpanded.json'),
    //           flightSummaries: require('@/assets/dev/cfs/CreativeFlightingSummaryAlt.json'),
    //           metadata: {
    //               totalScreens: mockScreens,
    //               successfulScreens: mockScreens - this.fetchProgress.errors.length,
    //               failedScreens: this.fetchProgress.errors.length,
    //               errors: this.fetchProgress.errors
    //           }
    //       };
    //   }

      // Production mode - real API calls
      const { cid, bid, sid } = this.$route.query;
      if (!cid || !bid || !sid) {
          throw new Error('Missing required query parameters: cid, bid, or sid');
      }

      const campaignResponse = await CampaignController.getCampaign(cid);
      const campaign = campaignResponse.data;

      // Convert query params to arrays
      const burstIds = bid.split(',');
      const screenIds = sid.split(',');

      // Filter selected bursts and screens
      const selectedBursts = campaign.campaignBursts.filter(burst => 
          burstIds.includes(burst.id.toString())
      );

      const selectedScreens = [];
      selectedBursts.forEach(burst => {
          const screens = burst.campaignBurstScreens.filter(screen => 
              screenIds.includes(screen.id.toString())
          );
          screens.forEach(screen => screen.burst = burst); // Attach burst reference
          selectedScreens.push(...screens);
      });

      this.fetchProgress.totalScreens = selectedScreens.length;

      const processedData = {
          campaign: {
              id: campaign.id,
              name: campaign.name
          },
          screenContents: [],
          flightSummaries: [],
          metadata: {
              totalScreens: selectedScreens.length,
              successfulScreens: 0,
              failedScreens: 0,
              errors: []
          }
      };

      // Process each screen concurrently
      await Promise.all(selectedScreens.map(async (screen) => {
          this.fetchProgress.currentScreen = screen.id;
          
          try {
              // Fetch screen contents and flight summaries in parallel
              const [screenContentsRes, flightSummaryRes] = await Promise.all([
                  CampaignController.getCampaignBurstScreenContentsExpanded(screen.id),
                  CampaignController.getCreativeFlightingSummaryAlt({
                      campaignBurstScreenId: screen.id,
                      frameId: "",
                      date: moment().format('YYYY-MM-DD'),
                      time: moment().format('HH:mm:ss'),
                      live: "Published",
                      width: screen.screen.width,
                      height: screen.screen.height
                  })
              ]);

              // Process screen contents
              const processedCreatives = screenContentsRes.data.map(creative => {
                  const triggers = JSON.parse(creative.triggers);
                  if (!triggers.date) {
                      triggers.date = {
                          startDate: screen.burst.startDate,
                          endDate: screen.burst.endDate
                      };
                  }
                  return {
                      ...creative,
                      triggers,
                      cbsStartDate: screen.burst.startDate,
                      cbsEndDate: screen.burst.endDate
                  };
              });

              // Add to processed data
              processedData.screenContents.push(...processedCreatives);
              processedData.flightSummaries.push(...flightSummaryRes.data);
              
              // Update progress
              this.fetchProgress.processedScreens++;
              this.fetchProgress.percentComplete = 
                  Math.round((this.fetchProgress.processedScreens / this.fetchProgress.totalScreens) * 100);
              
              processedData.metadata.successfulScreens += 1;
          } catch (error) {
              processedData.metadata.failedScreens += 1;
              processedData.metadata.errors.push(`Screen ${screen.id}: ${error.message}`);
              this.fetchProgress.errors.push(`Screen ${screen.id}: ${error.message}`);
              console.error(`Error processing screen ${screen.id}:`, error);
          }
      }));

      return processedData;
  } catch (error) {
      this.fetchProgress.errors.push(`General error: ${error.message}`);
      throw error;
  } finally {
      this.fetchProgress.isLoading = false;
      this.fetchProgress.currentScreen = null;
  }
},

        handleCellHover(d, isEntering) {
            if (isEntering) {
                const [row, col] = d;
                const media = this.mediaRows[col];  // Changed from row to col
                const location = this.uniqueLocations[row];  // Changed from col to row

                // Check if cell has content
                const cellContent = this.getCellContent(media.id, location);
                const hasContent = Boolean(cellContent);

                this.hoveredCell = d;
                this.hoverData = {
                    mediaId: media.id,
                    location: location,
                    position: { row, col },
                    hasContent: hasContent,
                    contentUrl: cellContent
                };
            } else {
                this.hoveredCell = null;
                this.hoverData = {
                    mediaId: null,
                    location: null,
                    position: null,
                    hasContent: false,
                    contentUrl: null
                };
            }
        },


        updateCellSize(value) {
            this.cellSize = Number(value);
            this.debounceUpdate();
        },
        setupKeyboardListeners() {
            window.addEventListener('keydown', this.handleKeyDown);
            window.addEventListener('keyup', this.handleKeyUp);
        },
        handleKeyDown(event) {
            if (event.key === 'Control' || event.key === 'Meta') {
                this.isCtrlPressed = true;
                return;
            }
            if (this.selectedCells.size !== 1) return;

            const [currentCell] = Array.from(this.selectedCells).map(key => key.split(',').map(Number));
            let handled = false;

            switch (event.key) {
                case 'ArrowUp':    // Moves up through locations
                    handled = this.findNextCellWithData(currentCell, 'up');
                    break;
                case 'ArrowDown':  // Moves down through locations
                    handled = this.findNextCellWithData(currentCell, 'down');
                    break;
                case 'ArrowLeft':  // Moves left through media files
                    handled = this.findNextCellWithData(currentCell, 'left');
                    break;
                case 'ArrowRight': // Moves right through media files
                    handled = this.findNextCellWithData(currentCell, 'right');
                    break;
                default:
                    return;
            }

            if (handled) {
                event.preventDefault();
                event.stopPropagation();
            }
        },


        findNextCellWithData(currentCell, direction) {
            const [currentRow, currentCol] = currentCell;
            let newRow = currentRow;
            let newCol = currentCol;

            // Define search parameters based on direction
            const searchParams = {
                // Now moving up/down navigates through locations
                up: { row: -1, col: 0, boundary: row => row >= 0 },
                down: { row: 1, col: 0, boundary: row => row < this.uniqueLocations.length },
                // Left/right navigates through media files
                left: { row: 0, col: -1, boundary: col => col >= 0 },
                right: { row: 0, col: 1, boundary: col => col < this.mediaRows.length }
            };

            const params = searchParams[direction];

            // Search for the next cell with data
            while (true) {
                newRow += params.row;
                newCol += params.col;

                // Check boundaries
                if (!params.boundary(params.row !== 0 ? newRow : newCol)) {
                    return false;
                }

                // Check if the cell has data
                const location = this.uniqueLocations[newRow]; // Location is now row
                const media = this.mediaRows[newCol];         // Media is now column

                if (location && media) {
                    const content = this.getCellContent(media.id, location);
                    if (content) {
                        // Found a cell with data, select it
                        this.handleCellSelection([newRow, newCol], { ctrlKey: false, metaKey: false });
                        return true;
                    }
                }
            }
        },

        handleKeyUp(event) {
            if (event.key === 'Control' || event.key === 'Meta') {
                this.isCtrlPressed = false;
            }
        },
        setupResizeObserver() {
            this.resizeObserver = new ResizeObserver(debounce(() => {
                // Store current selections before redrawing
                const currentSelections = new Set(this.selectedCells);

                // Reinitialize the grid
                this.initializeGrid();

                // Restore selections and update styles
                this.$nextTick(() => {
                    this.selectedCells = currentSelections;
                    this.updateCellStyles();

                    // If using auto-zoom and only one cell is selected, maintain the zoom
                    if (this.autoZoomEnabled && currentSelections.size === 1) {
                        const [cellKey] = currentSelections;
                        const [row, col] = cellKey.split(',').map(Number);
                        this.zoomToCell([row, col]);
                    }
                });
            }, 300));

            if (this.$refs.gridContainer) {
                this.resizeObserver.observe(this.$refs.gridContainer);
            }
        },
        initializeGrid() {
            // First update the grid dimensions based on static data.
            this.gridWidth = this.mediaRows.length;
            this.gridHeight = this.uniqueLocations.length;
            // Now validate inputs.
            if (!this.validateInputs()) return;
            if (!this.$refs.mainGrid) return;
            this.clearGrids();
            this.setupMainGrid();
            if (this.svg) {
                this.setupHeaders();
                this.setupZoom();
                if (this.currentTransform) {
                    this.applyTransform(this.currentTransform);
                }
            }
        },
        validateInputs() {
            return this.gridWidth > 0 &&
                this.gridHeight > 0 &&
                this.cellSize >= 50 &&
                this.cellSize <= 200;
        },
        clearGrids() {
            ['mainGrid', 'columnHeaders', 'rowHeaders'].forEach(ref => {
                d3.select(this.$refs[ref]).selectAll('*').remove();
            });
        },
        setupMainGrid() {
            try {
                this.svg = d3.select(this.$refs.mainGrid)
                    .append('svg')
                    .attr('width', '100%')
                    .attr('height', '100%');

                this.mainGroup = this.svg.append('g');

                // Build cells using uniqueLocations (rows) and mediaRows (columns)
                const cells = [];
                for (let row = 0; row < this.gridHeight; row++) {
                    for (let col = 0; col < this.gridWidth; col++) {
                        cells.push([row, col]);
                    }
                }

                const cellGroups = this.mainGroup.selectAll('.cell-group')
                    .data(cells)
                    .enter()
                    .append('g')
                    .attr('class', 'cell-group');

                // Draw the cell background
                cellGroups.append('rect')
                    .attr('class', 'cell')
                    .attr('x', d => d[1] * this.cellSize) // col (media) determines x
                    .attr('y', d => d[0] * this.cellSize) // row (location) determines y
                    .attr('width', this.cellSize)
                    .attr('height', this.cellSize)
                    .attr('fill', 'none')
                    .attr('stroke', 'rgba(255, 255, 255, 0.2)')
                    .style('pointer-events', 'all');

                // Add the image inside each cell using the updated lookup function
                cellGroups.append('image')
                    .attr('x', d => d[1] * this.cellSize + 7) // col (media) determines x
                    .attr('y', d => d[0] * this.cellSize + 7) // row (location) determines y
                    .attr('width', this.cellSize - 14)
                    .attr('height', this.cellSize - 14)
                    .attr('preserveAspectRatio', 'xMidYMid meet')
                    .attr('href', d => this.getImageForCell(d))
                    .style('pointer-events', 'none');

                // Add an invisible overlay to capture clicks
                cellGroups.append('rect')
                    .attr('class', 'cell-overlay')
                    .attr('x', d => d[1] * this.cellSize) // col (media) determines x
                    .attr('y', d => d[0] * this.cellSize) // row (location) determines y
                    .attr('width', this.cellSize)
                    .attr('height', this.cellSize)
                    .attr('fill', 'transparent')
                    .attr('stroke', 'none')
                    .style('cursor', 'cell')
                    .style('pointer-events', 'all')
                    .on('click', (event, d) => {
                        event.stopPropagation();
                        this.handleCellSelection(d, event);
                    })
                    .on('mouseenter', (event, d) => {
                        this.handleCellHover(d, true);
                    })
                    .on('mouseleave', (event, d) => {
                        this.handleCellHover(d, false);
                    });
            } catch (error) {
                console.error('Error setting up main grid:', error);
            }
        },
        // Updated to use both mediaRows and uniqueLocations.
        getImageForCell(d) {
            const rowIndex = d[0];  // Now represents location index
            const colIndex = d[1];  // Now represents media index
            const location = this.uniqueLocations[rowIndex];
            const media = this.mediaRows[colIndex];
            if (!location || !media) return null;
            return this.getCellContent(media.id, location);
        },
        // Returns the cell image URL by matching the media ID and location against the summaries.
        getCellContent(mediaId, location) {
            const dateKey = this.selectedDateTime.toISOString();
            const cacheKey = `${dateKey}-${mediaId}-${location}`;
            if (this.cellContentCache.has(cacheKey)) {
                return this.cellContentCache.get(cacheKey);
            }
            const relevantSummaries = this.summariesByDateTime.get(dateKey) || [];
            const match = relevantSummaries.find(summary =>
                summary.internalId === mediaId && summary.groups.includes(location)
            );
            const result = match ? match.proofOfPlayMedia.url : '';
            this.cellContentCache.set(cacheKey, result);
            return result;
        },
        // Format date/time in British English style.
        formatDateTime(dateTimeStr) {
            return new Date(dateTimeStr).toLocaleString('en-GB', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                hour12: false
            });
        },
        // Initialise caches for date formatting and mapping summaries by dateTime.
        initializeCaches() {
            this.flightSummaries.forEach(summary => {
                const dateKey = new Date(summary.dateTime).toISOString();
                this.formattedDateTimes[dateKey] = this.formatDateTime(summary.dateTime);
                if (!this.summariesByDateTime.has(dateKey)) {
                    this.summariesByDateTime.set(dateKey, []);
                }
                this.summariesByDateTime.get(dateKey).push(summary);
            });
        },
        clearCaches() {
            this.cellContentCache.clear();
        },
        updateImageUrls() {
            if (!this.mainGroup) return;
            this.mainGroup.selectAll('image')
                .attr('href', d => this.getImageForCell(d));
        },
        setupHeaders() {
            // Column headers (media files)
            const columnHeadersSvg = d3.select(this.$refs.columnHeaders)
                .append('svg')
                .attr('width', '100%')
                .attr('height', this.headerSize);

            this.columnHeadersGroup = columnHeadersSvg.append('g');

            // Column Headers (media files)
            const columnHeaders = this.columnHeadersGroup
                .selectAll('.column-label-container')
                .data(this.mediaRows)
                .enter()
                .append('g')
                .attr('class', 'column-label-container')
                .attr('transform', (d, i) => `translate(${i * this.cellSize}, 0)`);  // Initial positioning

            columnHeaders
                .append('foreignObject')
                .attr('class', 'column-header-foreign')
                .attr('width', this.cellSize)
                .attr('height', this.headerSize)
                .style('overflow', 'hidden')
                .append('xhtml:div')
                .style('width', '100%')
                .style('height', '100%')
                .style('display', 'flex')
                .style('align-items', 'center')
                .style('justify-content', 'center')
                .style('text-align', 'center')
                .style('color', '#FFFFFF')
                .style('font-size', '12px')
                .style('padding', '8px')
                .style('word-wrap', 'break-word')
                .style('overflow-wrap', 'break-word')
                .text(d => d.id === 0 ? 'Default' : d.media.originalFileName);

            // Row Headers (locations)
            const rowHeadersSvg = d3.select(this.$refs.rowHeaders)
                .append('svg')
                .attr('width', this.headerSize)
                .attr('height', '100%');

            this.rowHeadersGroup = rowHeadersSvg.append('g');

            const rowHeaders = this.rowHeadersGroup
                .selectAll('.row-label-container')
                .data(this.uniqueLocations)
                .enter()
                .append('g')
                .attr('class', 'row-label-container')
                .attr('transform', (d, i) => `translate(0, ${i * this.cellSize})`);  // Initial positioning

            rowHeaders
                .append('foreignObject')
                .attr('class', 'row-header-foreign')
                .attr('width', this.headerSize)
                .attr('height', this.cellSize)
                .style('overflow', 'hidden')
                .append('xhtml:div')
                .style('width', '100%')
                .style('height', '100%')
                .style('display', 'flex')
                .style('align-items', 'center')
                .style('justify-content', 'center')
                .style('text-align', 'center')
                .style('color', '#FFFFFF')
                .style('font-size', '12px')
                .style('padding', '8px')
                .style('word-wrap', 'break-word')
                .style('overflow-wrap', 'break-word')
                .text(d => d);
        },

        updateHeaderPositions(transform) {
            // Update column headers
            this.columnHeadersGroup.selectAll('.column-label-container')
                .attr('transform', (d, i) => {
                    const x = transform.applyX(i * this.cellSize);
                    return `translate(${x}, 0)`;
                });

            // Update row headers
            this.rowHeadersGroup.selectAll('.row-label-container')
                .attr('transform', (d, i) => {
                    const y = transform.applyY(i * this.cellSize);
                    return `translate(0, ${y})`;
                });
        }
        ,

        setupZoom() {
            if (!this.svg) return;

            // Calculate the minimum scale that fits the grid.
            const container = this.$refs.mainGrid;
            const containerWidth = container.clientWidth;
            const containerHeight = container.clientHeight;
            const gridPixelWidth = this.gridWidth * this.cellSize;
            const gridPixelHeight = this.gridHeight * this.cellSize;
            const scaleX = containerWidth / gridPixelWidth;
            const scaleY = containerHeight / gridPixelHeight;
            const minScale = Math.min(scaleX, scaleY);

            let dragStartPosition = null;
            let isDragging = false;

            const handleMouseDown = (event) => {
                if (event.target.closest('.cell-overlay')) return;
                dragStartPosition = {
                    x: event.clientX,
                    y: event.clientY,
                    transform: this.currentTransform ? { ...this.currentTransform } : d3.zoomIdentity
                };
                isDragging = false;
            };

            const handleMouseMove = (event) => {
                if (!dragStartPosition) return;
                const dx = event.clientX - dragStartPosition.x;
                const dy = event.clientY - dragStartPosition.y;
                const dragDistance = Math.sqrt(dx * dx + dy * dy);

                if (dragDistance > this.dragThreshold) {
                    isDragging = true;
                    const newTransform = d3.zoomIdentity
                        .translate(
                            dragStartPosition.transform.x + dx,
                            dragStartPosition.transform.y + dy
                        )
                        .scale(dragStartPosition.transform.k || 1);

                    this.currentTransform = newTransform;
                    this.applyTransform(newTransform);
                }
            };

            const handleMouseUp = (event) => {
                if (!dragStartPosition) return;
                const wasDragging = isDragging;
                dragStartPosition = null;
                isDragging = false;

                if (!wasDragging && !event.target.closest('.cell-overlay')) {
                    const [x, y] = d3.pointer(event, this.$refs.mainGrid);
                    const row = Math.floor(y / this.cellSize);
                    const col = Math.floor(x / this.cellSize);

                    if (row >= 0 && row < this.gridHeight && col >= 0 && col < this.gridWidth) {
                        this.handleCellSelection([row, col], event);
                    }
                }
            };

            this.cleanup = () => {
                this.svg
                    .on('mousedown.zoom', null)
                    .on('mousemove.zoom', null)
                    .on('mouseup.zoom', null)
                    .on('mouseleave.zoom', null);
            };

            this.svg
                .on('mousedown.zoom', handleMouseDown)
                .on('mousemove.zoom', handleMouseMove)
                .on('mouseup.zoom', handleMouseUp)
                .on('mouseleave.zoom', () => {
                    dragStartPosition = null;
                    isDragging = false;
                });

            // Set the zoom behaviour, using minScale as the minimum allowed zoom level.
            this.zoom = d3.zoom()
                .scaleExtent([minScale, 30])
                .on('zoom', (event) => {
                    this.currentTransform = event.transform;
                    this.applyTransform(event.transform);
                });

            this.svg.call(this.zoom);
        },

        applyTransform(transform) {
            // Update main grid
            this.mainGroup.attr('transform', transform);

            // Update headers
            this.updateHeaderPositions(transform);

            // Scale the header containers to match cell size
            const headerScale = transform.k;
            // Calculate font size based on zoom (max of 12px)
            const baseFontSize = 12;
            const fontSize = Math.min(baseFontSize, baseFontSize * headerScale);

            this.columnHeadersGroup.selectAll('.column-header-foreign')
                .attr('width', this.cellSize * headerScale)
                .attr('height', this.headerSize);

            this.rowHeadersGroup.selectAll('.row-header-foreign')
                .attr('width', this.headerSize)
                .attr('height', this.cellSize * headerScale);

            // Update font size with maximum of 12px
            this.columnHeadersGroup.selectAll('foreignObject div')
                .style('font-size', `${fontSize}px`)
                .style('width', `${this.cellSize * headerScale}px`)
                .style('height', `${this.headerSize}px`);

            this.rowHeadersGroup.selectAll('foreignObject div')
                .style('font-size', `${fontSize}px`)
                .style('width', `${this.headerSize}px`)
                .style('height', `${this.cellSize * headerScale}px`);
        },
        zoomToCell(d) {
            if (!this.svg || !this.zoom) return;
            const container = this.$refs.mainGrid;
            const cellWidth = this.cellSize;
            const cellHeight = this.cellSize;
            const scaleX = container.clientWidth / (cellWidth * 1.2);
            const scaleY = container.clientHeight / (cellHeight * 1.2);
            const scale = Math.min(scaleX, scaleY);
            const tx = (container.clientWidth / 2) - ((d[1] + 0.5) * cellWidth * scale);
            const ty = (container.clientHeight / 2) - ((d[0] + 0.5) * cellHeight * scale);
            const transform = d3.zoomIdentity
                .translate(tx, ty)
                .scale(scale);
            this.svg.transition()
                .duration(600)
                .ease(d3.easeQuadInOut)
                .call(this.zoom.transform, transform);
        },
        handleCellSelection(d, event) {
            const [row, col] = d;
            const cellKey = `${row},${col}`;
            const selection = new Set(this.selectedCells);
            const wasSelected = selection.has(cellKey);

            // Verify the cell coordinates are valid
            if (row < 0 || row >= this.uniqueLocations.length ||
                col < 0 || col >= this.mediaRows.length) {
                return;
            }

            if (event.ctrlKey || event.metaKey) {
                wasSelected ? selection.delete(cellKey) : selection.add(cellKey);
            } else {
                if (selection.size === 1 && wasSelected) {
                    selection.clear();
                } else {
                    selection.clear();
                    selection.add(cellKey);
                }
            }

            this.selectedCells = new Set(selection);
            this.updateCellStyles();

            if (this.autoZoomEnabled && selection.size === 1) {
                this.$nextTick(() => {
                    this.zoomToCell(d);
                });
            }
        },
        updateCellStyles() {
            if (!this.mainGroup) return;

            // Clear all previous selections first
            this.mainGroup.selectAll('.cell-group rect.cell')
                .attr('fill', 'none')
                .attr('stroke', 'rgba(255, 255, 255, 0.2)')
                .attr('stroke-width', 1);

            // Apply new selections
            const selectedSet = this.selectedCells;
            this.mainGroup.selectAll('.cell-group')
                .each(function (d) {
                    const cellKey = `${d[0]},${d[1]}`;
                    if (selectedSet.has(cellKey)) {
                        d3.select(this).select('rect.cell')
                            .attr('fill', 'rgba(64, 158, 255, 0.2)')
                            .attr('stroke', '#409EFF')
                            .attr('stroke-width', 2);
                    }
                });
        },

        resetView() {
            if (!this.svg || !this.zoom) return;

            // Get container dimensions.
            const container = this.$refs.mainGrid;
            const containerWidth = container.clientWidth;
            const containerHeight = container.clientHeight;

            // Compute grid dimensions.
            const gridPixelWidth = this.gridWidth * this.cellSize;
            const gridPixelHeight = this.gridHeight * this.cellSize;

            // Calculate the minimum scale (fit-to-grid).
            const scaleX = containerWidth / gridPixelWidth;
            const scaleY = containerHeight / gridPixelHeight;
            const scale = Math.min(scaleX, scaleY);

            // Calculate translation to centre the grid.
            const tx = (containerWidth - gridPixelWidth * scale) / 2;
            const ty = (containerHeight - gridPixelHeight * scale) / 2;

            const transform = d3.zoomIdentity.translate(tx, ty).scale(scale);

            // Transition to the calculated transform.
            this.svg.transition()
                .duration(750)
                .ease(d3.easeQuadInOut)
                .call(this.zoom.transform, transform);

            this.currentTransform = transform;
        },
    }
};
</script>

<style scoped>

.main-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.top-controls {
    width: 100%;
    padding: 16px;
    background-color: #1E1E1E;
    z-index: 4;
}

.content-row {
    display: flex;
    flex: 1;
    min-height: 0;
    width: 100%;
}

.grid-selector {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1;
    min-width: 0;
}

.right-panel {
    width: 300px;
    height: 100%;
    background-color: #2A2A2A;
    border-left: 1px solid #333;
}

.right-panel-content {
    height: 100%;
    overflow-y: auto;
}

.bottom-row {
    width: 100%;
    height: 150px;
    background-color: rgb(43, 43, 43);
}

.grid-container {
    position: relative;
    flex: 1;
    min-height: 0;
    background-color: #1E1E1E;
    outline: none;
}

.fixed-corner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100px;
    height: 100px;
    background-color: #1E1E1E;
    border-right: 1px solid #333;
    border-bottom: 1px solid #333;
    z-index: 3;
}

.fixed-column-headers {
    position: absolute;
    top: 0;
    left: 100px;
    right: 0;
    height: 100px;
    background-color: #1E1E1E;
    z-index: 2;
    border-bottom: 1px solid #333;
    overflow: hidden;
}

.fixed-row-headers {
    position: absolute;
    top: 100px;
    left: 0;
    width: 100px;
    bottom: 0;
    background-color: #1E1E1E;
    z-index: 2;
    border-right: 1px solid #333;
    overflow: hidden;
}

.main-grid {
    position: absolute;
    top: 100px;
    left: 100px;
    right: 0;
    bottom: 0;
    overflow: hidden;
}

:deep(.header-label) {
    fill: #FFFFFF !important;
    font-size: 16px;
    font-weight: 500;
    user-select: none;
}

:deep(.cell-group) {
    cursor: pointer;
}

:deep(.cell) {
    transition: all 0.2s ease;
    pointer-events: all;
}

:deep(.cell-group:hover .cell) {
    fill: rgba(255, 255, 255, 0.1);
}

:deep(.cell-group image) {
    pointer-events: none;
}

:deep(.cell-overlay) {
    pointer-events: all;
}

:deep(.v-list-item__title) {
    font-weight: 500;
    line-height: 1.2;
}

:deep(.v-list-item__subtitle) {
    opacity: 0.7;
    font-size: 0.75rem !important;
}

:deep(.v-list-item) {
    padding: 8px 0;
}

:deep(.column-header-foreign),
:deep(.row-header-foreign) {
    overflow: hidden !important;
}

:deep(.column-label-container),
:deep(.row-label-container) {
    pointer-events: none;
}

:deep(.column-header-foreign div),
:deep(.row-header-foreign div) {
    transform-origin: center center;
    transition: none;
}
</style>